// Store
import store from "@/store";

export interface AppPartnerAccountCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  bank_id: string | string[];
  content: {
    accountNumber: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    currentBalance: {
      hrk: string,
      eur: string,
    };
  }
}

export class AppPartnerAccountCreateIndex implements AppPartnerAccountCreateIndex {
  organization_id: string | string[];
  partner_id: string | string[];
  bank_id: string | string[];
  content: {
    accountNumber: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    currentBalance: {
      hrk: string,
      eur: string,
    };
  }

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.partner_id = "";
    this.bank_id = "";
    this.content = {
      accountNumber: "",
      initialBalance: {
        hrk: "",
        eur: "",
      },
      currentBalance: {
        hrk: "",
        eur: "",
      },
    };
  }
}