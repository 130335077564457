
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElButton,
  ElCollapse,
  ElCollapseItem,
  ElTable,
  ElTableColumn,
  ElMessageBox,
} from "element-plus";

// Services
import {deleteRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceStatus, getInvoiceStatusClass, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "invoicePayments",
  ],
  components: {
    ElDialog,
    ElButton,
    ElCollapse,
    ElCollapseItem,
    ElTable,
    ElTableColumn,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  invoicePayments: Array<AppInvoicePaymentIndex> = [];

  formData: AppInvoicePaymentIndex = new AppInvoicePaymentIndex();

  get isInvoicePaymentsSet(): boolean {
    return this.invoicePayments.length > 0;
  }

  get invoicePaymentsOutgoingInvoices(): Array<AppInvoicePaymentIndex> {
    return this.invoicePayments.filter((item: AppInvoicePaymentIndex) => item.outgoing_invoice_id !== null);
  }

  get invoicePaymentsIncomingInvoices(): Array<AppInvoicePaymentIndex> {
    return this.invoicePayments.filter((item: AppInvoicePaymentIndex) => item.incoming_invoice_id !== null);
  }

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "amount",
      "amountWithVAT",
      "amountWithoutVAT",
      "areYouSure",
      "cancel",
      "compensation",
      "date",
      "dateOfExchangeRate",
      "dateOfClosing",
      "information",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceType",
      "invoicePayment",
      "incomingInvoice",
      "incomingInvoices",
      "outgoingInvoice",
      "outgoingInvoices",
      "paidAmount",
      "partner",
      "placeOfCost",
      "status",
      "remove",
      "review",
      "unitAmount",
      "unitDiscount",
      "unitPrice",
    ]);
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatus(data: string): string {
    return getInvoiceStatus(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  getIsInvoicePaymentCompensation(data: AppInvoicePaymentIndex): boolean {
    return data.is_compensation === true;
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await deleteRequest({
      uri: "/common/invoice-payment/remove",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit("getTransactions");
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFromData(data: AppInvoicePaymentIndex): void {
    this.formData = data;

    ElMessageBox.confirm(this.translation.areYouSure, this.translation.remove, {
      confirmButtonText: this.translation.yesIAm,
      cancelButtonText: this.translation.cancel,
      type: "warning",
    }).then(() => {
      this.submitForm();
    }).catch(() => {
      this.formData = new AppInvoicePaymentIndex();
    });
  }

  resetFormData(): void {
    this.formData = new AppInvoicePaymentIndex();
  }
}
