
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPartnerAccountIndex} from "@/models/app/partner-account";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

// Services
import {getInformation} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partnerAccount",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  partnerAccount: AppPartnerAccountIndex = new AppPartnerAccountIndex();

  get translation(): any {
    return getTranslation([
      "bank",
      "currentBalance",
      "initialBalance",
      "accountNumber",
      "review",
    ]);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }
}
