import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cdc3c2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-partners-details-transactions-form-create-outgoing-invoice-compensation-payment" }
const _hoisted_2 = { class: "accounting-payroll" }
const _hoisted_3 = { class: "accounting-payroll__headline" }
const _hoisted_4 = { class: "accounting-payroll__information" }
const _hoisted_5 = { class: "accounting-payroll__information-label" }
const _hoisted_6 = { class: "accounting-payroll__information-value" }
const _hoisted_7 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_8 = { class: "accounting-payroll__information" }
const _hoisted_9 = { class: "accounting-payroll__information-label" }
const _hoisted_10 = { class: "accounting-payroll__information-value" }
const _hoisted_11 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_12 = { class: "accounting-payroll__information" }
const _hoisted_13 = { class: "accounting-payroll__information-label" }
const _hoisted_14 = { class: "accounting-payroll__information-value" }
const _hoisted_15 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_16 = { class: "accounting-payroll" }
const _hoisted_17 = { class: "accounting-payroll__headline" }
const _hoisted_18 = { class: "accounting-payroll__information" }
const _hoisted_19 = { class: "accounting-payroll__information-label" }
const _hoisted_20 = { class: "accounting-payroll__information-value" }
const _hoisted_21 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_22 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_23 = { class: "accounting-payroll__information" }
const _hoisted_24 = { class: "accounting-payroll__information-label" }
const _hoisted_25 = { class: "accounting-payroll__information-value" }
const _hoisted_26 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_27 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_28 = { class: "accounting-payroll__information" }
const _hoisted_29 = { class: "accounting-payroll__information-label" }
const _hoisted_30 = { class: "accounting-payroll__information-value" }
const _hoisted_31 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_32 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_33 = { class: "accounting-payroll" }
const _hoisted_34 = { class: "accounting-payroll__headline" }
const _hoisted_35 = { class: "accounting-payroll__information" }
const _hoisted_36 = { class: "accounting-payroll__information-label" }
const _hoisted_37 = { class: "accounting-payroll__information-value" }
const _hoisted_38 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_39 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_40 = { class: "accounting-payroll__information" }
const _hoisted_41 = { class: "accounting-payroll__information-label" }
const _hoisted_42 = { class: "accounting-payroll__information-value" }
const _hoisted_43 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_44 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_45 = { class: "accounting-payroll__information" }
const _hoisted_46 = { class: "accounting-payroll__information-label" }
const _hoisted_47 = { class: "accounting-payroll__information-value" }
const _hoisted_48 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_49 = { class: "accounting-payroll__information-value__currency" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.pay,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.translation.transaction), 1)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translation.amount), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.transaction.content.amount.eur)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translation.spent), 1),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.spentAmount)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.translation.remaining), 1),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.remainingAmount)), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.ordinalNumber,
                      prop: "compensation_ordinal_number"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          step: "1",
                          modelValue: _ctx.formData.compensation_ordinal_number,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.compensation_ordinal_number) = $event)),
                          placeholder: _ctx.translation.ordinalNumberPlaceholder
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.translation.year,
                      prop: "compensation_year"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          step: "1",
                          modelValue: _ctx.formData.compensation_year,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.compensation_year) = $event)),
                          placeholder: _ctx.translation.yearPlaceholder
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.type,
              prop: "type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.type,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.type) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.standard,
                      value: "standard"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.multiple,
                      value: "multiple"
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.outgoingInvoice,
              prop: "outgoing_invoice_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.outgoing_invoice_id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.outgoing_invoice_id) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.outgoingInvoices, (outgoingInvoice) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: outgoingInvoice.id,
                        label: `${_ctx.getInvoiceNumberInYear(outgoingInvoice.content.invoiceNumberInYear, outgoingInvoice.content.year)} · ${outgoingInvoice.content.invoiceNumber} · ${_ctx.translation.amount}: ${_ctx.getCurrencyFormatEUR(outgoingInvoice.content.amount.eur)} · ${_ctx.translation.paidAmount}: ${_ctx.getCurrencyFormatEUR(outgoingInvoice.content.paidAmount.eur)}`,
                        value: outgoingInvoice.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.partners,
              prop: "partner_id",
              class: _normalizeClass({'d-none': _ctx.isCompensationTypeStandard})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.partner_id,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.partner_id) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: partner.id,
                        label: partner.content.title,
                        value: partner.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.incomingInvoice,
              prop: "incoming_invoice_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.incoming_invoice_id,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.incoming_invoice_id) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomingInvoices, (incomingInvoice) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: incomingInvoice.id,
                        label: `${_ctx.getInvoiceNumberInYear(incomingInvoice.content.invoiceNumberInYear, incomingInvoice.content.year)} · ${incomingInvoice.content.invoiceNumber} · ${_ctx.translation.amount}: ${_ctx.getCurrencyFormatEUR(incomingInvoice.content.amount.eur)} · ${_ctx.translation.paidAmount}: ${_ctx.getCurrencyFormatEUR(incomingInvoice.content.paidAmount.eur)}`,
                        value: incomingInvoice.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.translation.outgoingInvoice), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.translation.amount), 1),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.outgoingInvoice.content.amount.hrk)), 1),
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.outgoingInvoice.content.amount.eur)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.translation.paidAmount), 1),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.outgoingInvoice.content.paidAmount.hrk)), 1),
                      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.outgoingInvoice.content.paidAmount.eur)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.translation.difference), 1),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.getCurrencyFormatHRK(parseFloat(_ctx.outgoingInvoice.content.amount.hrk - _ctx.outgoingInvoice.content.paidAmount.hrk).toFixed(2))), 1),
                      _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.getCurrencyFormatEUR(parseFloat(_ctx.outgoingInvoice.content.amount.eur - _ctx.outgoingInvoice.content.paidAmount.eur).toFixed(2))), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.translation.incomingInvoice), 1)
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.translation.amount), 1),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.incomingInvoice.content.amount.hrk)), 1),
                      _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.incomingInvoice.content.amount.eur)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.translation.paidAmount), 1),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.getCurrencyFormatHRK(_ctx.incomingInvoice.content.paidAmount.hrk)), 1),
                      _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.incomingInvoice.content.paidAmount.eur)), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.translation.difference), 1),
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.getCurrencyFormatHRK(parseFloat(_ctx.incomingInvoice.content.amount.hrk - _ctx.incomingInvoice.content.paidAmount.hrk).toFixed(2))), 1),
                      _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.getCurrencyFormatEUR(parseFloat(_ctx.incomingInvoice.content.amount.eur - _ctx.incomingInvoice.content.paidAmount.eur).toFixed(2))), 1)
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.date,
              prop: "content.date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.formData.content.date,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.content.date) = $event)),
                  placeholder: _ctx.translation.titlePlaceholderDate,
                  format: "DD.MM.YYYY."
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: `${_ctx.translation.amount} (HRK)`,
                      prop: "content.amount.hrk"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.content.amount.hrk,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.content.amount.hrk) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderAmount,
                          disabled: ""
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: `${_ctx.translation.amount} (EUR)`,
                      prop: "content.amount.eur"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          step: "0.01",
                          modelValue: _ctx.formData.content.amount.eur,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.content.amount.eur) = $event)),
                          placeholder: _ctx.translation.titlePlaceholderAmount
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  disabled: _ctx.isPaymentDisabled,
                  loading: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    (_ctx.isPayAndContinue)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_50, _toDisplayString(_ctx.translation.payAndContinue), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.translation.pay), 1))
                  ]),
                  _: 1
                }, 8, ["disabled", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}