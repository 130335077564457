
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppTransactionIndex} from "@/models/app/transaction";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";
import {AppPartnerIndex} from "@/models/app/partner";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElTable,
  ElTableColumn,
  ElButton,
} from "element-plus";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/lookup-incoming-invoice-payment.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/lookup-outgoing-invoice-payment.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/create-incoming-invoice-payment.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/create-outgoing-invoice-payment.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/create-incoming-invoice-compensation-payment.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/form/create-outgoing-invoice-compensation-payment.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat} from "@/services/app/date";
import {getCurrencyFormatHRK, getCurrencyFormatEUR} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue extends Vue {
  isLoading = false;

  transaction: AppTransactionIndex = new AppTransactionIndex();
  transactions: Array<AppTransactionIndex> = [];
  invoicePaymentsIncoming: Array<AppInvoicePaymentIndex> = [];
  invoicePaymentsOutgoing: Array<AppInvoicePaymentIndex> = [];
  partners: Array<AppPartnerIndex> = [];

  $refs!: {
    AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue: any;
    AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue: any;
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue: any;
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue: any;
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue: any;
    AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue: any;
  };

  get isDataLoaded(): boolean {
    return this.transactions.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.transactions.length === 0 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "action",
      "bank",
      "amount",
      "compensation",
      "date",
      "excerpt",
      "information",
      "invoicePayment",
      "invoicePayments",
      "noData",
      "transaction",
      "transactions",
      "partner",
      "pay",
      "paymentPurpose",
      "paymentType",
      "remaining",
      "review",
      "undefined",
    ]);
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getMaximumAmount(transaction: AppTransactionIndex): number {
    let invoicePaymentsAmount = 0;
    let transactionAmount = parseFloat(transaction.content.amount.eur);

    switch (transaction.payment_type.content.title) {
      case "D":
        transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.incoming_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
      case "P":
        transaction.invoice_payments.forEach((invoicePayment: AppInvoicePaymentIndex) => {
          if (invoicePayment.outgoing_invoice_id) {
            invoicePaymentsAmount = invoicePaymentsAmount + parseFloat(invoicePayment.content.amount.eur);
          }
        });
        break;
    }

    return this.getDifferenceAmount(transactionAmount, invoicePaymentsAmount);
  }

  getDifferenceAmount(transactionAmount: number, invoicePaymentsAmount: number): number {
    const differenceAmount = Math.round((transactionAmount - invoicePaymentsAmount) * 100) / 100;

    if (differenceAmount.toFixed(2) === "-0.01") {
      return 0;
    }

    if (differenceAmount.toFixed(2) === "-0.00") {
      return 0;
    }

    return differenceAmount;
  }

  getRemainingAmount(transaction: AppTransactionIndex): string {
    return this.getMaximumAmount(transaction).toFixed(2);
  }

  getPartner(): void {
    this.$emit('getPartner');
  }

  isTransactionSpent(transaction: AppTransactionIndex): boolean {
    return this.getMaximumAmount(transaction) <= 0;
  }

  async getTransactions(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `common/transactions/by-partner`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.transactions = r.data;

        setTimeout(() => {
          if (this.transaction.id !== "") {
            this.transaction = this.transactions.find((item: AppTransactionIndex) => item.id === this.transaction.id);
            const transactionEl = document.getElementById(`Transaction-${this.transaction.id}`);

            if (transactionEl) {
              transactionEl.scrollIntoView();
            }
          }
        }, 100);

        this.$emit("getIncomingInvoicesByPartnerStatistic");
        this.$emit("getOutgoingInvoicesByPartnerStatistic");
        this.$emit("setRemainingAmountTotal", this.setRemainingAmountTotal());
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
    this.isLoading = false;
  }

  setRemainingAmountTotal(): number {
    let totalRemainingAmount = 0;

    this.transactions.forEach((transaction: AppTransactionIndex) => {
      totalRemainingAmount += this.getMaximumAmount(transaction);
    });

    return totalRemainingAmount;
  }

  setDialogLookupIncomingInvoicePayment(data: Array<AppInvoicePaymentIndex>) {
    this.invoicePaymentsIncoming = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogLookupOutgoingInvoicePayment(data: Array<AppInvoicePaymentIndex>) {
    this.invoicePaymentsOutgoing = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateIncomingInvoicePayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateOutgoingInvoicePayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateIncomingInvoiceCompensationPayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue.isDialogVisible = true;
  }

  setDialogCreateOutgoingInvoiceCompensationPayment(data: AppTransactionIndex) {
    this.transaction = data;
    this.$refs.AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue.isDialogVisible = true;
  }

  async created(): Promise<void> {
    await this.getTransactions();
    await this.getPartners();
  }
}
