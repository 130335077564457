
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPartnerAccountIndex} from "@/models/app/partner-account";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {
  Edit,
} from "@element-plus/icons-vue";

// Services
import {putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "partnerAccount",
    "banks",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Edit,
  },
  watch: {
    'formData.content.initialBalance.eur'(): void {
      this.formData.content.initialBalance.hrk = (this.formData.content.initialBalance.eur * 7.53450).toFixed(2);
    },
    'formData.content.currentBalance.eur'(): void {
      this.formData.content.currentBalance.hrk = (this.formData.content.currentBalance.eur * 7.53450).toFixed(2);
    },
  },
})
export default class AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  partnerAccount: AppPartnerAccountIndex = new AppPartnerAccountIndex();

  formData: AppPartnerAccountIndex = new AppPartnerAccountIndex();
  formDataRules = {
    bank_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      accountNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "bank",
      "choose",
      "edit",
      "accountNumber",
      "save",
      "titlePlaceholderNumberOfAccount",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: "/common/partner-account/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPartnerAccounts');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.partnerAccount;
  }

  resetFormData(): void {
    this.formData = new AppPartnerAccountIndex();
  }

  updated(): void {
    this.setFormData();
  }
}
