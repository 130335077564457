
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationMasterDataVue from "@/components/administrator/navigation-master-data.vue";
import AppAdministratorMasterDataPartnersDetailsInformationIndexVue from "@/views/app/administrator/master-data/partners/details/information/index.vue";
import AppAdministratorMasterDataPartnersDetailsAccountsIndexVue from "@/views/app/administrator/master-data/partners/details/accounts/index.vue";
import AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue from "@/views/app/administrator/master-data/partners/details/transactions/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationMasterDataVue,
    AppAdministratorMasterDataPartnersDetailsInformationIndexVue,
    AppAdministratorMasterDataPartnersDetailsAccountsIndexVue,
    AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsIndexVue extends Vue {
  $refs!: {
    AppAdministratorMasterDataPartnersDetailsInformationIndexVue: any;
  };

  getPartner(): void {
    this.$refs.AppAdministratorMasterDataPartnersDetailsInformationIndexVue.getPartner();
  }

  getIncomingInvoicesByPartnerStatistic(): void {
    this.$refs.AppAdministratorMasterDataPartnersDetailsInformationIndexVue.getIncomingInvoicesByPartnerStatistic();
  }

  getOutgoingInvoicesByPartnerStatistic(): void {
    this.$refs.AppAdministratorMasterDataPartnersDetailsInformationIndexVue.getOutgoingInvoicesByPartnerStatistic();
  }

  setRemainingAmountTotal(remainingAmountTotal: number): void {
    this.$refs.AppAdministratorMasterDataPartnersDetailsInformationIndexVue.setRemainingAmountTotal(remainingAmountTotal);
  }
}
