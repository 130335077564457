// Models
import {AppBankIndex} from "@/models/app/bank";

export interface AppPartnerAccountIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  bank_id: string | string[];
  content: {
    accountNumber: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    currentBalance: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  bank: AppBankIndex;
}

export class AppPartnerAccountIndex implements AppPartnerAccountIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  bank_id: string | string[];
  content: {
    accountNumber: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    currentBalance: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  bank: AppBankIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      accountNumber: "",
      initialBalance: {
        hrk: "",
        eur: "",
      },
      currentBalance: {
        hrk: "",
        eur: "",
      },
    };
    this.created_at = "";
    this.updated_at = "";
    this.bank = new AppBankIndex();
  }
}