import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b6db87d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-partners-details-accounts" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = {
  key: 0,
  class: "box__list"
}
const _hoisted_7 = { class: "item__content" }
const _hoisted_8 = { class: "item__content-name" }
const _hoisted_9 = { class: "item__content-information" }
const _hoisted_10 = { class: "item__action" }
const _hoisted_11 = {
  key: 1,
  class: "box__message"
}
const _hoisted_12 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorMasterDataPartnersDetailsAccountsFormCreateIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsAccountsFormCreateIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsAccountsFormRemoveIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsAccountsFormRemoveIndexVue")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.accounts), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorMasterDataPartnersDetailsAccountsFormCreateIndexVue, {
            banks: _ctx.banks,
            onGetPartnerAccounts: _ctx.getPartnerAccounts
          }, null, 8, ["banks", "onGetPartnerAccounts"])
        ])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerAccounts, (partnerAccount) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: partnerAccount.id
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(partnerAccount.bank.content.title), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(partnerAccount.content.accountNumber), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue, { "partner-account": partnerAccount }, null, 8, ["partner-account"]),
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue, {
                    "partner-account": partnerAccount,
                    banks: _ctx.banks,
                    onGetPartnerAccounts: _ctx.getPartnerAccounts
                  }, null, 8, ["partner-account", "banks", "onGetPartnerAccounts"]),
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsAccountsFormRemoveIndexVue, {
                    "partner-account": partnerAccount,
                    onGetPartnerAccounts: _ctx.getPartnerAccounts
                  }, null, 8, ["partner-account", "onGetPartnerAccounts"])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}