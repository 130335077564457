
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppPartnerAccountIndex} from "@/models/app/partner-account";
import {AppBankIndex} from "@/models/app/bank";
import {ResponseIndex} from "@/models/response";

// Components
import AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue from "@/views/app/administrator/master-data/partners/details/accounts/form/lookup.vue";
import AppAdministratorMasterDataPartnersDetailsAccountsFormCreateIndexVue from "@/views/app/administrator/master-data/partners/details/accounts/form/create.vue";
import AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue from "@/views/app/administrator/master-data/partners/details/accounts/form/update.vue";
import AppAdministratorMasterDataPartnersDetailsAccountsFormRemoveIndexVue from "@/views/app/administrator/master-data/partners/details/accounts/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    AppAdministratorMasterDataPartnersDetailsAccountsFormLookupIndexVue,
    AppAdministratorMasterDataPartnersDetailsAccountsFormCreateIndexVue,
    AppAdministratorMasterDataPartnersDetailsAccountsFormUpdateIndexVue,
    AppAdministratorMasterDataPartnersDetailsAccountsFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsAccountsIndexVue extends Vue {
  isLoading = false;

  partnerAccounts: Array<AppPartnerAccountIndex> = [];
  banks: Array<AppBankIndex> = [];

  get isDataLoaded(): boolean {
    return this.partnerAccounts.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.partnerAccounts.length === 0 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "accounts",
      "noData",
    ]);
  }

  async getPartnerAccounts(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/partner-accounts`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.$route.params.id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partnerAccounts = r.data;
      }
    });
    this.isLoading = false;
  }

  async getBanks(): Promise<void> {
    await getRequest({
      uri: `/common/banks`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.banks = r.data;
      }
    });
  }

  async created(): Promise<void> {
    await this.getPartnerAccounts();
    await this.getBanks();
  }
}
