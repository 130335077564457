
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppPlaceIndex} from "@/models/app/place";
import {ResponseIndex} from "@/models/response";

// Components
import AppAdministratorMasterDataPartnersDetailsInformationFormUpdateIndexVue from "@/views/app/administrator/master-data/partners/details/information/form/update.vue";
import AppAdministratorMasterDataPartnersDetailsInformationFormRemoveIndexVue from "@/views/app/administrator/master-data/partners/details/information/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    AppAdministratorMasterDataPartnersDetailsInformationFormUpdateIndexVue,
    AppAdministratorMasterDataPartnersDetailsInformationFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorMasterDataPartnersDetailsInformationIndexVue extends Vue {
  isLoading = false;

  partner: AppPartnerIndex = new AppPartnerIndex();
  places: Array<AppPlaceIndex> = [];
  remainingAmountTotal = "0.00";

  outgoingInvoicesStatistic: {
    amount: string,
    paidAmount: string,
    difference: string,
  } = {
    amount: "0",
    paidAmount: "0",
    difference: "0",
  };

  incomingInvoicesStatistic: {
    amount: string,
    paidAmount: string,
    difference: string,
  } = {
    amount: "0",
    paidAmount: "0",
    difference: "0",
  };

  get isDataLoaded(): boolean {
    return this.partner && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "address",
      "identificationNumber",
      "incomingInvoices",
      "paidEstimateExit",
      "paidEstimateEntry",
      "outgoingInvoices",
      "place",
      "phone",
      "vatNumber",
    ]);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  async getOutgoingInvoicesByPartnerStatistic(): Promise<void> {
    await getRequest({
      uri: `/common/outgoing-invoices/by-partner-statistic`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.outgoingInvoicesStatistic = r.data;
      }
    });
  }

  async getIncomingInvoicesByPartnerStatistic(): Promise<void> {
    await getRequest({
      uri: `/common/incoming-invoices/by-partner-statistic`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.incomingInvoicesStatistic = r.data;
      }
    });
  }

  async getPartner(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/partner`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        id: this.$route.params.id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partner = r.data;
      }
    });
    this.isLoading = false;
  }

  async getPlaces(): Promise<void> {
    await getRequest({
      uri: `/common/places`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.places = r.data;
      }
    });
  }

  setRemainingAmountTotal(remainingAmountTotal: number): void {
    this.remainingAmountTotal = remainingAmountTotal.toFixed(2);
  }

  async created(): Promise<void> {
    await this.getPartner();
    await this.getPlaces();
  }
}
