import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-administrator-master-data" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "app-administrator-master-data-partners-details" }
const _hoisted_4 = { class: "section-default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdministratorNavigationVue = _resolveComponent("AdministratorNavigationVue")!
  const _component_AdministratorNavigationMasterDataVue = _resolveComponent("AdministratorNavigationMasterDataVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsInformationIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsInformationIndexVue")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_AppAdministratorMasterDataPartnersDetailsAccountsIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsAccountsIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdministratorNavigationVue),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AdministratorNavigationMasterDataVue),
        _createElementVNode("section", _hoisted_4, [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsInformationIndexVue, { ref: "AppAdministratorMasterDataPartnersDetailsInformationIndexVue" }, null, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsAccountsIndexVue)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsIndexVue, {
                    onGetPartner: _ctx.getPartner,
                    onGetIncomingInvoicesByPartnerStatistic: _ctx.getIncomingInvoicesByPartnerStatistic,
                    onGetOutgoingInvoicesByPartnerStatistic: _ctx.getOutgoingInvoicesByPartnerStatistic,
                    onSetRemainingAmountTotal: _ctx.setRemainingAmountTotal
                  }, null, 8, ["onGetPartner", "onGetIncomingInvoicesByPartnerStatistic", "onGetOutgoingInvoicesByPartnerStatistic", "onSetRemainingAmountTotal"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}