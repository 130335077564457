import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6910cd22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-partners-details-transactions" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "box__list"
}
const _hoisted_6 = ["id"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "el-table-column__action" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "el-table-column__action" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "el-table-column__action" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = {
  key: 1,
  class: "box__message"
}
const _hoisted_20 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue")!
  const _component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue = _resolveComponent("AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.transactions), 1)
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_el_table, {
              data: _ctx.transactions,
              "min-height": "400",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.bank}`,
                  width: "90"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", {
                      id: `Transaction-${scope.row.id}`
                    }, _toDisplayString(scope.row.excerpt.bank.content.title.substring(0, 10)) + "...", 9, _hoisted_6)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.excerpt.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.excerpt.content.ordinalNumber), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.transaction.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(" #" + _toDisplayString(scope.row.content.ordinalNumber), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.date}`,
                  width: "80"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.excerpt.content.date)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentType.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.payment_type.content.title), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.partner}`,
                  width: "200"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.partner === null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.translation.undefined), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(scope.row.partner.content.title.substring(0,24)), 1))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentPurpose}`,
                  "min-width": "360"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.content.paymentPurpose), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.amount}`,
                  width: "110"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatHRK(scope.row.content.amount.hrk)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.amount}`,
                  width: "110"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.amount.eur)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.remaining}`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(_ctx.getRemainingAmount(scope.row))), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.action}`,
                  align: "center",
                  width: "110",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_9, [
                      (scope.row.payment_type.content.title === 'D')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                            _createVNode(_component_el_button, {
                              class: "el-button--primary el-button--extra-small el-button--block",
                              disabled: _ctx.isTransactionSpent(scope.row),
                              onClick: ($event: any) => (_ctx.setDialogCreateIncomingInvoicePayment(scope.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translation.pay), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                            _createVNode(_component_el_button, {
                              class: "el-button--primary el-button--extra-small el-button--block",
                              disabled: _ctx.isTransactionSpent(scope.row),
                              onClick: ($event: any) => (_ctx.setDialogCreateOutgoingInvoicePayment(scope.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translation.pay), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]))
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.compensation}`,
                  align: "center",
                  width: "110",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_12, [
                      (scope.row.payment_type.content.title === 'D')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            _createVNode(_component_el_button, {
                              class: "el-button--primary el-button--extra-small el-button--block",
                              disabled: _ctx.isTransactionSpent(scope.row),
                              onClick: ($event: any) => (_ctx.setDialogCreateIncomingInvoiceCompensationPayment(scope.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translation.pay), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                            _createVNode(_component_el_button, {
                              class: "el-button--primary el-button--extra-small el-button--block",
                              disabled: _ctx.isTransactionSpent(scope.row),
                              onClick: ($event: any) => (_ctx.setDialogCreateOutgoingInvoiceCompensationPayment(scope.row))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translation.pay), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]))
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoicePayments}`,
                  align: "center",
                  width: "110",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_15, [
                      (scope.row.payment_type.content.title === 'D' && scope.row.invoice_payments.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                            _createVNode(_component_el_button, {
                              class: "el-button--primary-dark el-button--extra-small el-button--block",
                              onClick: ($event: any) => (_ctx.setDialogLookupIncomingInvoicePayment(scope.row.invoice_payments))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translation.review), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                        : (scope.row.payment_type.content.title === 'P' && scope.row.invoice_payments.length)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                              _createVNode(_component_el_button, {
                                class: "el-button--primary-dark el-button--extra-small el-button--block",
                                onClick: ($event: any) => (_ctx.setDialogLookupOutgoingInvoicePayment(scope.row.invoice_payments))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translation.review), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.translation.undefined), 1))
                    ])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupIncomingInvoicePaymentIndexVue",
      "invoice-payments": _ctx.invoicePaymentsIncoming,
      onGetTransactions: _ctx.getTransactions
    }, null, 8, ["invoice-payments", "onGetTransactions"]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormLookupOutgoingInvoicePaymentIndexVue",
      "invoice-payments": _ctx.invoicePaymentsOutgoing,
      onGetTransactions: _ctx.getTransactions
    }, null, 8, ["invoice-payments", "onGetTransactions"]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoicePaymentIndexVue",
      transaction: _ctx.transaction,
      onGetTransactions: _ctx.getTransactions,
      onGetPartner: _ctx.getPartner
    }, null, 8, ["transaction", "onGetTransactions", "onGetPartner"]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoicePaymentIndexVue",
      transaction: _ctx.transaction,
      onGetTransactions: _ctx.getTransactions,
      onGetPartner: _ctx.getPartner
    }, null, 8, ["transaction", "onGetTransactions", "onGetPartner"]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateIncomingInvoiceCompensationPaymentIndexVue",
      transaction: _ctx.transaction,
      partners: _ctx.partners,
      onGetTransactions: _ctx.getTransactions,
      onGetPartner: _ctx.getPartner
    }, null, 8, ["transaction", "partners", "onGetTransactions", "onGetPartner"]),
    _createVNode(_component_AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue, {
      ref: "AppAdministratorMasterDataPartnersDetailsTransactionsFormCreateOutgoingInvoiceCompensationPaymentIndexVue",
      transaction: _ctx.transaction,
      partners: _ctx.partners,
      onGetTransactions: _ctx.getTransactions,
      onGetPartner: _ctx.getPartner
    }, null, 8, ["transaction", "partners", "onGetTransactions", "onGetPartner"])
  ]))
}